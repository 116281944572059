export default {
    'common': {
        'employees': 'Angajati',
        'welcome': 'Bine ati venit',
        'firstName': 'Nume',
        'lastName': 'Prenume',
        'save': 'Salveaza',
        'add': 'Adauga',
        'yes': 'Da',
        'cancel': 'Anulare',
        'sure': 'Sigur',
        'logout': 'Deconectare'
    },
    'employees': {
        'addEmployee': 'Adauga angajat',
        'editEmployee': 'Modifica angajat',
        'searchDates': 'Cauta pe date',
        'grid': {
            'day': 'Ziua',
            'times': 'Ora',
            'worked': 'Lucrat',
            'absent': 'Absent',
        }
    },
    'timeline': {
        'addTimeline': 'Adauga ora',
        'editTimeline': 'Modifica ora',
        'absent': 'Absent'
    },
    'alert': {
        'confirm': 'Sunteti sigur!',
        'deleteUser': 'Vreti sa îl ştergeţi pe {firstName} {lastName}!',
        'deleteTimeline': 'Sterge {date} {startTime} - {endTime}'
    },
    'auth': {
        'username': 'E-mail',
        'password': 'Parola',
        'login': 'Autentificare',
        'authenticateToYourAccount': 'Autentifică-te la contul tau',
        'apple': 'Autentificare Apple',
        'google': 'Autentificare Google',
    }
}
