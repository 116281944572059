// export const API_PREFIX = 'http://myhouse-admin.test/api/v1';
export const API_PREFIX = 'https://myhouse.voina.nl/api/v1';

// Auth
export const API_AUTH_LOGIN = API_PREFIX + '/auth/login'
export const API_AUTH_SOCIAL_LOGIN = API_PREFIX + '/auth/social_login'

// Epmloyees
export const API_EMPLOYEES_INDEX = API_PREFIX + '/employees';
export const API_EMPLOYEES_ADD = API_PREFIX + '/employees/add';
export const API_EMPLOYEES_EDIT = API_PREFIX + '/employees/edit';
export const API_EMPLOYEES_VIEW = API_PREFIX + '/employees/view';
export const API_EMPLOYEES_DELETE = API_PREFIX + '/employees/delete';

// Timeline
export const API_EMPLOYEES_ADD_TIMESHEET = API_PREFIX + '/employees/timesheet/add';
export const API_EMPLOYEES_VIEW_TIMESHEET = API_PREFIX + '/employees/timesheet/view';
export const API_EMPLOYEES_EDIT_TIMESHEET = API_PREFIX + '/employees/timesheet/edit';
export const API_EMPLOYEES_DELETE_TIMESHEET = API_PREFIX + '/employees/timesheet/delete';

// Users
export const API_USER = API_PREFIX + '/users';
