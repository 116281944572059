// Auth
export const ROUTE_LOGIN = '/login';

// Products
export const ROUTE_EMPLOYEES = '/employees';
export const ROUTE_EMPLOYEES_ADD = '/employees/add';
export const ROUTE_EMPLOYEES_VIEW = '/employees/view';
export const ROUTE_EMPLOYEES_EDIT = '/employees/edit';

// Timeline
export const ROUTE_TIMELINE_ADD = '/timeline/add';
export const ROUTE_TIMELINE_EDIT = '/timeline/edit';