
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent } from 'vue';

// Components
import MainMenu from '@/components/MainMenu.vue';

// Constants
import {ROUTE_LOGIN} from '@/constants/routes';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet, 
    IonSplitPane,
    MainMenu,
  },
  setup() {
    return {
      ROUTE_LOGIN    }
  }
});
