import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import {ROUTE_EMPLOYEES, ROUTE_LOGIN, ROUTE_EMPLOYEES_ADD, ROUTE_EMPLOYEES_VIEW, ROUTE_TIMELINE_ADD, ROUTE_EMPLOYEES_EDIT, ROUTE_TIMELINE_EDIT} from "@/constants/routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: ROUTE_EMPLOYEES
  },
  {
    path: ROUTE_EMPLOYEES_ADD,
    component: () => import ('../views/Employees/Add.vue')
  },
  {
    path: `${ROUTE_EMPLOYEES_VIEW}/:employeeId`,
    component: () => import ('../views/Employees/View.vue')
  },
  {
    path: `${ROUTE_EMPLOYEES_EDIT}/:employeeId`,
    component: () => import ('../views/Employees/Edit.vue')
  },
  {
    path: ROUTE_EMPLOYEES,
    component: () => import ('../views/Employees/Index.vue')
  },
  {
    path: `${ROUTE_TIMELINE_ADD}/:employeeIds`,
    component: () => import ('../views/Timeline/Add.vue')
  },
  {
    path: `${ROUTE_TIMELINE_EDIT}/:timesheetId`,
    component: () => import ('../views/Timeline/Edit.vue')
  },
  {
    path: ROUTE_LOGIN,
    component: () => import ('../views/Auth/Login.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
